#page-wrap{
	width: 100%;
	position: relative;
	overflow-x: hidden;

	body:not(.index) & {
		padding-top:rem(50px);
		@include breakpoint($break) {
			padding-top:rem(170px);
		}
	}
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	body:not(.index) &{
		@include breakpoint($break, max) {
			min-height: rem(180px);
		}
	}


	.branding {
		display: inline-block;
		top: rem(45px);

		@include breakpoint($break, max) {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
		}

		@include breakpoint(tiny) {
			top: rem(30px);
		}

		@include breakpoint($break) {
			position: absolute;
			top: rem(24px);
			z-index: 10;
			left: rem($base-gap);
		}

		@include breakpoint(cut) {
			left: rem($base-gap*2);			
		}

		@include breakpoint(max) {
			left: calc(50vw - #{rem($rowMaxWidth / 2)});			
		}

		body:not(.index) & {
			@include breakpoint($break) {
				top:rem(-150px);
			}
		}

		body.cms-template-edit & {
			position: static !important;
			margin-left: 0 !important;
			transform: translateX(0) !important;
		}

		img {
			max-height: rem(115px);
			width: auto;
		}
	}

	.header-social-block {		

			position: fixed;
			display: flex;
			flex-direction: row;
			width: 100%;
			right: unset;
			top: unset;
			bottom: 0rem;
			z-index: 999;
			justify-content: space-evenly;
			padding: 0 2rem 1rem;

			&:before {
				content: "";
				background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))!important;
				width: 100%;
				position: absolute;
				height: 7rem;
				bottom: 0;
				top: unset;
				z-index: -1;
				left: unset;
				transform: none;
			}
			&:after {
				content: none;
			}
			@include breakpoint(small) {
				position: fixed;
				top: 37vh;
				bottom: unset;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: flex-end;
				height: 33vh;
				padding: unset;

				&:before {
					top: rem(-119px);
				}
				&:after {
					bottom: rem(-119px);
				}
				&:before,
				&:after {
				content: '';
				width: 1px;
				height: rem(118px);
				display: block;
				right: 33px;
				background: image-url('layout/dots-separator-dark.png') 0 0 repeat-y!important;
				position: absolute;

				body.ueber-uns-team & {
					background: image-url('layout/dots-separator-white.png') 0 0 repeat-y;
				}
				}
			}

		body:not(.index) & {
			li.openings div {
				top: -466%;
				@include breakpoint(large) {
					bottom: calc(100% - #{rem(350px)});
					right: 0;
					top: unset;
				}
			}
			@include breakpoint (large) {
				position: absolute;
				top: rem(-109px);
				right: unset;
				display: flex;
				justify-content: center;
				flex-direction: row;
				align-items: flex-start;
				max-height: 50px;
	
				&:before,
				&:after {
					display: none;
				}
			}
		}

		body.index & {
			@include breakpoint(large) {		
				position: absolute;
				width: rem(46px);
				right: rem($base-gap);
				z-index: 900;
				top: rem(260px);
				
				&:before,
				&:after {
					right: 23px;
				}
			}
			
			@include breakpoint(giant) {		
				top: rem(260px);
			}
			@include breakpoint(huge) {		
				top: rem(300px);
			}
			@include breakpoint(full) {
				top: 20rem;
			}

			@include breakpoint(full2) {
				top: 23rem;
				right: 93px;
			}

			@include breakpoint(max) {
				top: 21rem;
			}
		}
		

		li {
			margin: rem(10px) 0;
			
			body:not(.index) & {
				margin: 0 rem(10px);				
			}

			@include breakpoint (small) {
				margin: 0 rem(10px);
			}
			@include breakpoint (large) {
				margin: rem(10px) 0;
			}

			&.openings {
				display: block;
				width: rem(46px);
				height: rem(46px);
				border-radius: 100%;
				background: $primary;
				text-decoration: none;
				position: unset;
				pointer-events: all;
				cursor: pointer;

				&:before {
					width: 100%;
					height: 100%;
					transition: .3s;
					color: $light;
					display: flex;
					justify-content: center;
					align-items: center;
					content: map-get($icon-map, clock);
					font-family: $icon-font;
					font-size: rem(22px);
				}
				
				@include breakpoint(small) {
					position: relative;
				}

				div {
					position: absolute;
					background: $light;
					min-width: rem(275px);
					width: auto;
					top: -345%;
					left: 50%;
					transform: translateX(-50%);
					height: auto;
					padding: rem($base-gap);
					visibility: hidden;
					opacity: 0;
					transition: visibility .3s ease-in-out, opacity .3s ease-in-out;
					pointer-events: none;
					box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1), 0 0 0 2px #fff, 0.3em 0.3em 1em rgba(0,0,0,0.3);
					z-index: 100;
					text-align: center;

					@include breakpoint(small) {
						bottom: 8%;
						top: unset;
						right: 123%;
						height: fit-content;
						transform: none;
						left: unset;
					}

					
					
					dl {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						max-width: rem(215px);
						text-align: left;
						margin: 0 auto;

						dd, dt {
							display: inline;
						}

						dt {
							margin-right: rem(5px);
							font-weight: 700;
						}

						dd {
							&:after {
								content: 'Uhr';
								margin-left: rem(5px);
							}
						}
					}
				}

				&:hover, &:focus {
					div {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}

		a {
			display: block;
			width: rem(46px);
			height: rem(46px);
			border-radius: 100%;
			background: $primary;
			text-decoration: none;

			// body.ueber-uns-team & {
			// 	//background: $secondary;
			// }

			&:hover,
			&:focus,
			&.active {
				&:before {
					opacity: 0.7;
				}
			}

			&:before {
				width: 100%;
				height: 100%;
				transition: .3s;
				color: $light;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&.phone {
				font-size: 0;

				&:before {
					content: map-get($icon-map, phone);
					font-family: $icon-font;
					font-size: rem(22px);
				}
			}
			&.email {
				font-size: 0;

				&:before {
					content: map-get($icon-map, envelope);
					font-family: $icon-font;
					font-size: rem(22px);
				}
			}
			&.whatsapp {
				font-size: 0;
				
				&:before {
					content: map-get($icon-map, whatsapp-white);
					font-family: $icon-font;
					font-size: rem(20px);
					color: $light;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			span {
				display: none;
			}
		}
	}

	.swiper-container {
		width: 100%;
		height: 100%;
		padding-bottom: rem(170px);
		overflow: hidden;

		@include breakpoint(large, max) {
			margin-top: rem(40px);
		}
		@include breakpoint(cut) {
			padding-bottom: rem(200px);
		}

		&:before {
			content: '';
			width: 100%;
			height: rem(350px);
			display: block;
			position: absolute;
			top: rem(169px);
			background-color: $medium; 

			@include breakpoint(large) {
				height: rem(400px);
			}
			@include breakpoint(giant) {
				height: rem(475px);
			}
			@include breakpoint(huge) {
				height: rem(550px);
			}
			@include breakpoint(full) {
				height: rem(680px);
			} 
			@include breakpoint(cut) {
				height: rem(744px);
			}
		}
		
		.swiper-wrapper {
			display: block;
			height: 100%;

			.swiper-slide:not(:first-child) {
				display: none;
			}
		}
		&.swiper-container-initialized {
			overflow: visible !important;

        	.swiper-wrapper {
	        	display: flex !important;
	        	height: 100% !important;	        	

	        	.swiper-slide {
	        		height: 100% !important;
	        		display: block !important;
	        	}
	        }
        }

		.image-col {
			@include breakpoint(full) {
				width: 53%;
				flex-basis: 53%;
			}
		}

		.swiper-slide {
	    	background-size: cover;
	    	height: 100%;
	    	width: 100%;

	    	.claim-box {
	    		font-size: rem(40px);
	    		line-height: 1.06em;
	    		font-weight: 300;
	    		color: $darkest;
	    		text-transform: uppercase;

	    		@include breakpoint(tiny) {
	    			font-size: rem(50px);
	    		}
	    		@include breakpoint(large, max) {
	    			text-align: center;
	    		}

	    		@include breakpoint(huge) {
					font-size: rem(60px);
	    		}
	    		@include breakpoint(full) {
	    			font-size: rem(79px);
	    		}
	    		@include breakpoint(cut) {
	    			font-size: rem(79px);
	    		}

	    		.bold {
	    			//display: block;
	    			font-weight: bold;
	    		}
	    	}
	    	.btn-termin {
    			position: absolute;
    			z-index: 1;
    			font-size: rem(26px);
    			line-height: 1.1em;
    			text-transform: uppercase;
    			text-align: center;
    			color: $darkest;
    			width: rem(316px);
				height: rem(291px);
    			padding-top: rem(12.5px);
				padding-left: rem(87.5px);
				left: rem(-75px);
				bottom: rem(-115px);

				@include breakpoint(small) {
					left: rem(-140px);
					bottom: rem(-80px);
				}
				@include breakpoint(large) {
					left: 0;
					bottom: rem(-115px);
				}
				@include breakpoint(giant) {
					bottom: rem(-130px);
					left: rem(-30px);
				}
				@include breakpoint(full) {
					bottom: rem(-75px);
					left: rem(-30px);
				}
				@include breakpoint(cut) {
					left: rem(-15px);
				}

				a {
					display: flex;
					justify-content: center;
					flex-direction: column;
					width: rem(177px);
					height: rem(177px);
					color: $darkest !important;

					&:hover,
	    			&:focus,
	    			&.active {
	    				color: $darkest !important;

	    				.circle-icon {
	    					background-color: $primary;
	    				}
	    			}			

	    			.bold {
	    				display: block;
	    				font-size: rem(29px);
	    				font-weight: bold;
	    			}

	    			.circle-icon {
	    				display: block;
	    				position: absolute;
	    				top: rem(146px);
	    				right: rem(60px);
	    				width: rem(52px);
	    				height: rem(52px);
	    				border-radius: 100%;
	    				background-color: $secondary;
	    				transition: 0.3s ease;

	    				&:before {
	    					content: map-get($icon-map, envelope);
							font-family: $icon-font;	
	    					font-size: rem(22px);
							color: $light;
	    					display: block;
	    					position: absolute;
	    					top: 50%;
	    					left: 50%;
	    					transform: translate(-50%, -50%);
	    				}
	    			}
    			}
    		}

	    	.image-box {
	    		position: relative;

	    		@include breakpoint(large) {
		    		left: rem(-20px);
					top: 10%;
		    	}

				@include breakpoint(giant) {
		    		left: rem(-50px);
					top: 15%;
		    	}

				@include breakpoint(full) {
					top: 5%;
					left: 0;
		    	}
	    		
	    		&:before,
	    		&:after {
	    			content: '';
	    			width: 100%;
		    		height: 100%;
		    		border-radius: 100%;
	    			display: block;
	    			position: absolute;
	    			left: 50%;
	    			bottom: -5%;
	    			transform: translateX(-50%) scale(.97);	

					@include breakpoint(large) {
						bottom: rem(-50px);
						transform: translateX(-50%) scale(.8);
					}
	    		}
	    		&:before {	    			
	    			box-shadow: 0px 0px rem(29px) rem(60px) rgba($light, 1);
	    			z-index: -1;

					@include breakpoint(large) {
						box-shadow: 0px 0px rem(29px) rem(100px) rgba($light, 1);
					}

					@include breakpoint(giant) {
						box-shadow: 0px 0px rem(29px) rem(115px) rgba($light, 1);
					}

					@include breakpoint(full) {
						box-shadow: 0px 0px rem(29px) rem(150px) rgba($light, 1);
					}
	    		}
	    		&:after {	    			
	    			box-shadow: 0px 0px rem(150px) 0px rgba($primary, .2);
	    			z-index: -2;
	    		}
	    	}
	    	
	    	&.slide1 {
	    			
	    		&.swiper-slide {
	    			.comb {
	    				animation: CustomFadeIn 2s;
	    			}	 			
	    		}
				.comb {
	    			@include breakpoint($break) {
		    			width: rem(400px);
		    			height: rem(391px);
		    			position: absolute;
		    			bottom: rem(-115px);
		    			left: rem(-70px);
		    			background: image-url('index/slider/comb.png') 0 0 no-repeat;
		    			background-size: cover;
						transform: rotate(-8deg);
						z-index: -1;
						opacity: .7;
		    		}

					@include breakpoint(full) {
						width: rem(608px);
						height: rem(594px);
						left: rem(-210px);
						bottom: 0;
						transform: none;
						opacity: .6;
					}

					@include breakpoint(max) {
						left: rem(-350px);
					}
		    	}
	    		.claim-box {
	    			padding-top: rem(200px);

					@include breakpoint(large) {
						padding-top: rem(220px);	    				
	    			}
	    			@include breakpoint(huge) {
						padding-top: rem(250px);	    				
	    			}
	    			@include breakpoint(full) {
		    			padding-top: rem(300px);
		    		}
	    			@include breakpoint(cut) {
		    			padding-top: rem(330px);
		    		}

					blockquote {
						span {
							&:not(.break) {
								display: block;
							}

							&.bold {
								font-size: clamp(#{rem(35px)}, 4.5vw, #{rem(60px)});
								line-height: 1.25;
							}

							&.small {
								font-size: clamp(#{rem(25px)}, 2vw, #{rem(30px)});
								line-height: 1.5;
								font-weight: 400;
							}

							&.break {
								@include breakpoint(extra-small) {
									display: block;
								}

								@include breakpoint(large) {
									display: inline;
								}
							}
						}
					}
	    		}
	    		
	    		.number {
	    			width: rem(30px);
	    			text-align: center;

	    			@include breakpoint(tiny) {
	    				width: rem(36px);
	    			}
	    		}	    		
	    	}
	    	&.slide1 {
	    		.btn-termin {
    				background: image-url('index/slider/anfrage-button.png') 0 0 no-repeat;	
    			}
	    	}	
	    }      	
	}
}

.fix-infobar {
	background-color: $primary;
	color: $light;
	padding: 2rem 0 8rem;
	position: relative;
	bottom: -100%;
	left: 0;
	width: 100%; 
	z-index: 40;
	visibility: hidden;
	opacity: 0;
	font-size: rem(17px);
	text-transform: uppercase;
	font-weight: 300; 
	transition: 0.3s ease; 
	text-align: center;

	@include breakpoint(small) {
		padding: rem(10px) 0;
	}

	@include breakpoint(large) {
		position: fixed;
		bottom: -100%;
	}
	@include breakpoint(giant) {
		padding: rem(20px) 0;
		text-align: left;
	}

	@include breakpoint(huge) {
		font-size: rem(18px);
	}
	@include breakpoint(full) {
		font-size: rem(20px);
	}

	body.scrolled & {
		bottom: 0;
		visibility: visible;
		opacity: 1;
	}

	.text {
		display: inline-block;
		padding: 0;

		@include breakpoint(giant) {
			padding: rem(5px) 0;			
		}
	}

	ul {		
		text-transform: none; 

		@include breakpoint(medium) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include breakpoint(giant) {
			justify-content: flex-end;
		}

		li {

			@include breakpoint(large, max) {
				margin-top: rem(8px);
			}
			
			&:not(:first-child) {
				position: relative;

				@include breakpoint(small) {
					margin-left: rem(40px);
				}

				@include breakpoint(huge) {
					margin-left: rem(55px);
				}

				@include breakpoint(medium) {
					&:before {
						content: '//';
						position: absolute;
						left: rem(-25px);
						top: 50%;
						transform: translateY(-50%);

						@include breakpoint(huge) {
							left: rem(-35px);
						}
					}
				}
			}

			a {
				&.whatsapp {
					position: relative;
					display: flex;
					align-items: center;
					color: $light;

					@include breakpoint(medium, max) {
						justify-content: center;
					}

					&:hover,
					&:focus {
						color: lighten($secondary, 10%);
					}

					span {
						display: block;
						width: rem(36px);
						height: rem(36px);
						margin-left: rem(14px);

						&.lazy-loaded {
							background-image: image-url('layout/icons/whatsapp-color.svg');		    			
						}
					}
				}

				&.email {
					color: lighten($secondary, 20%);

					&:hover, &:focus, &:active {
						opacity: .8;
					}
				}
			}	
		}
	}

	strong {
		font-weight: bold;
	}
}

// MAIN CONTENT
//////////////////////////////


main {
	display: block;
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
	}

	body.cms-page-edit & {
		padding-top: rem(50px);
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
	}

	.non-fluid {
		img {
			width: auto !important;
		}
	}

	.row {
		&.large-center {
			@include breakpoint(large) {
				align-items: center;
			}
		}
	}

	.first-section {
		body:not(.cms-liveeditor-is-opened) & {
			margin-top: rem(-100px);

			@include breakpoint(large) {
				margin-top: rem(-100px);
			}
			@include breakpoint(full) {
				margin-top: rem(-150px);
			}
		}

		.intro-claim {
			display: flex;

			@include breakpoint(large,max) {
				justify-content: center;
			}
		}

		.claim {
			font-family: $display-font;
			font-size: clamp(#{rem(35px)}, 5vw, #{rem($h1-size)});
			line-height: 1.2em;
			font-weight: 900;
			color:$primary;
			margin-bottom: rem(20px);

			span {
				display: block;
			}
		}
	}

	.animated-counter-box {
		display: flex;
		justify-content: center;

		.animated-counter {
			margin-bottom: rem(75px);
			text-align: center;

			@include breakpoint(tiny, max) {
				margin-bottom: rem(36px);				
			}

			.pie {
				margin: 0 auto;

				@include breakpoint(tiny, max) {
					width: 100% !important;
				}

				svg {
					margin-bottom: rem(5px);

					@include breakpoint(tiny, max) {
						width: 100% !important;
					}
				}

				text {
					font-weight: bold;
					font-size: rem(25px);
				}
			}

			.cms-title {
				font-size: rem(20px);
				text-transform: uppercase;
				color: $primary;
				font-weight: bold;

				span {
					display: block;

					@include breakpoint(tiny) {
						display: inline-block;
					}
					@include breakpoint(small) {
						display: block;
					}
					@include breakpoint(huge) {
						display: inline-block;
					}
				}
			}
		}
	}

	.parallax-teaser-area {
		.parallax-slider {
			@include breakpoint(medium) {
				min-height: rem(600px);
			}

			@include breakpoint(large) {
				min-height: rem(800px);
			}

			@include breakpoint(giant) {
				min-height: rem(1000px);
			}
		}

		@include breakpoint(medium) {
			.swiper-pagination {
				position: absolute;
				right: rem($base-gap);
				top: 50%;
				transform: translateY(-50%);
				width: rem($base-gap / 2);
			}
			.swiper-pagination-bullet {
	        	width: rem($base-gap / 1.5);
	        	height: rem($base-gap / 1.5);
	        	background: transparent;
				border: rem(1px) solid $secondary;
	        	opacity: 1;
	        	margin: rem(4px) 0;
	        }		
	        .swiper-pagination-bullet-active {
	        	background: $secondary;
	        }

	        .parallax-slider {
		        width: 100%;
		        height: 100%;
		    }
	    }

	    @include breakpoint(medium, max) {
	    	.swiper-wrapper {
				display: block !important;
			}
		}
		
		.teaser-area {
			color: $primary;
			background: $medium;
       		padding: rem(100px) 0;
       		overflow: hidden;

       		body:not(.cms-liveeditor-is-opened) & {

				@include breakpoint(medium) {
					//height: calc(100% - #{rem(200px)}) !important;
				}
       		}

			.row {
				height: 100%;
				@include breakpoint(medium) {
					align-items: center;

					body:not(.cms-liveeditor-is-opened) & {
						height: 100%;
					}
				}

				.col {
					@include breakpoint(giant) {
						body:not(.cms-liveeditor-is-opened) & {
							height: 100%;
						}
					}
				}
			}

			.parallax-title {
				display: block;
				color: $primary;
				font-weight: bold;
				font-size: rem(39px);
				text-transform: uppercase;
				line-height: 1.1em;
				margin-bottom: rem(18px);
				text-align: center;

				@include breakpoint(small) {
					font-size: rem(45px)
				}

				@include breakpoint(medium) {
					display: inline-block;
					text-align: left;	
				}
				@include breakpoint(large) {
					padding-left: rem(40px);
				}
				@include breakpoint(giant) {
					font-size: rem(50px);					
				}
				@include breakpoint(full) {
					font-size: rem(55px);					
				}
				@include breakpoint(cut) {
					font-size: rem(65px);
				}

				span {
					font-family: $display-font;
					display: block;
					font-weight: normal;
					text-transform: none;
					
					@include breakpoint(large) {
						text-indent: rem(-40px);
					}
				}
			}

			.box {
				position: relative;
				z-index: 3;

				@include breakpoint(giant, max) {
					transform: none !important;
				}

				> .cms-container-el {
					@include breakpoint(medium, max) {
						text-align: center;
						margin-bottom: rem($base-gap * 2);
					}
				}

				body:not(.cms-liveeditor-is-opened) & {
					@include breakpoint(large) {
						height: 100%;
						display: flex;
						align-items: center;
					}
				}

				.btn {
					width: auto;
					
					&:after {
						border:rem(6px) solid $medium;
					}
				}
			}

			.text-box {
				margin-bottom: rem($base-gap * 2);
				text-align: center;

				@include breakpoint(medium) {
					text-align: left;
					margin-bottom: rem($base-gap * 4);
				}
			}

			.image-box {

				@include breakpoint(medium) {
					padding-right: rem($base-gap * 1.5);
				}
				@include breakpoint(huge) {
					padding-right: 0;
				}
				
				body:not(.cms-liveeditor-is-opened) & {
					height: 100%;
				}

				.circle-image {
					text-align: center;

					body:not(.cms-liveeditor-is-opened) & {
						height: 100%;
					}

					figure {
						img {
							border: 1rem $light solid;
							border-radius: 50%;
							aspect-ratio: 1/1;
							object-fit: cover;
							width: 90vw;
							
							@include breakpoint(tiny) {
								width: 400px;
							}					
							@include breakpoint(small) {
							}
							@include breakpoint(medium) {
								width: 600px;
							}
							@include breakpoint(large) {
								width: 700px;
								border: 2rem $light solid;
							}			
						}
						@include breakpoint(giant, max) {
							transform: none !important;

							img {
								transform: none !important;					
							}
						}
						@include breakpoint(giant) {					
							display: flex;
							justify-content: center;
							align-items: center;
						}
					

						body:not(.cms-liveeditor-is-opened) & {
							position: relative;
							z-index: 1;
							height: 100%;
						}

						&:before,
						&:after {
							content: '';
							width: rem(214px);
							height: rem(500px);							
							display: block;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							z-index: -1;
							background-size: cover;

							@include breakpoint(small, max) {
								display: none;
							}
							@include breakpoint(large) {
								width: rem(257px);
								height: rem(600px);
							}
							@include breakpoint(giant) {
								width: rem(300px);
								height: rem(700px);
							}
							@include breakpoint(full) {	
								width: rem(385px);
								height: rem(900px);
								//top: rem(-70px);
							}
						}
						&:before {
							left: rem(-100px);
							
							@include breakpoint(large) {
								left: rem(-30px);
							}
							@include breakpoint(giant) {
								left: rem(-50px);
							}
							@include breakpoint(full) {
								left: rem(-90px);
							}
						}
						&:after {
							transform: scaleX(-1) translateY(-50%);
							right: rem(-100px);
							
							@include breakpoint(large) {
								right: rem(-30px);
							}
							@include breakpoint(giant) {
								right: rem(-70px);
							}
							@include breakpoint(full) {
								right: rem(-90px);
							}
						}

						img {
							// width: auto;
							//height: 100%;
							margin-bottom: 0;
						}

						// img[data-cms-image-lazy-loaded="true"] {
						// 	width: auto;
						// }
					}
				}

			}
			
			&.friseur {
				@include breakpoint(medium,max) {
					border-bottom: rem(1px) solid darken($medium, 7%);
				}

				.box {

				&:before {
						content: map-get($icon-map, scissors);
						font-family: $icon-font;
						font-size: rem(200px);
						color: darken($medium, 5%);
						opacity: .8;
						position: absolute;
						left: 40%;
						top: 5%;
						z-index: -1;

						@include breakpoint(large) {
							font-size: rem(250px);
							left: 30%;
							top: 10%;
						}

						@include breakpoint(giant) {
							font-size: rem(350px);
							left: 15%;
							top: 20%;
						}

						@include breakpoint(full) {
							font-size: rem(400px);
							left: 20%;
						}
					}
				}
			}

			&.salon {
				@include breakpoint(medium,max) {
					border-bottom: rem(1px) solid darken($medium, 7%);
				}

				.box {

				&:before {
						content: map-get($icon-map, chair);
						font-family: $icon-font;
						font-size: rem(315px);
						color: darken($medium, 5%);
						opacity: .8;
						position: absolute;
						left: 40%;
						top: 10%;
						z-index: -1;

						@include breakpoint(large) {
							font-size: rem(350px);
							left: 30%;
							top: 20%;
						}

						@include breakpoint(giant) {
							font-size: rem(450px);
							top: 25%;
						}

						@include breakpoint(full) {
							font-size: rem(550px);
							top: 35%;
						}
					}
				}
			}

			&.nagel {
				@include breakpoint(medium,max) {
					border-bottom: rem(1px) solid darken($medium, 7%);
				}

				.box {

				&:before {
						content: map-get($icon-map, nailpolish);
						font-family: $icon-font;
						font-size: rem(200px);
						color: darken($medium, 5%);
						opacity: .8;
						position: absolute;
						left: 55%;
						top: 5%;
						z-index: -1;

						@include breakpoint(large) {
							font-size: rem(250px);
							left: 40%;
							top: 10%;
						}

						@include breakpoint(giant) {
							font-size: rem(350px);
							left: 30%;
							top: 20%;
						}

						@include breakpoint(full) {
							font-size: rem(400px);
							top: 25%;
							left: 45%;
						}
					}
				}
			}

			&.schulung {
				.box {

				&:before {
						content: map-get($icon-map, people-info);
						font-family: $icon-font;
						font-size: rem(250px);
						color: darken($medium, 5%);
						opacity: .8;
						position: absolute;
						left: 35%;
						top: 5%;
						z-index: -1;

						@include breakpoint(tiny) {
							left: 40%;
						}

						@include breakpoint(medium) {
							top: 10%;
						}

						@include breakpoint(large) {
							top: 15%;
							left: 30%;
							font-size: rem(300px);
						}

						@include breakpoint(giant) {
							font-size: rem(350px);
							left: 30%;
							top: 20%;
						}

						@include breakpoint(full) {
							font-size: rem(400px);
							top: 25%;
							left: 40%;
						}
					}
				}
			}
		}
	}

	.brandslider-area {
		padding: rem(80px) 0;
		position: relative;
		color: $primary;

		@include breakpoint(small) {
			padding: rem(100px) 0;
		}

		.row-90 {
			@include breakpoint(giant) {
				padding-top: rem(100px);
			}

			h2 {
				font-family: $display-font;
				text-align: center;
				font-size: rem(50px);
				line-height: 1.2em;
				font-weight: normal;
				color: $primary;
				// margin-bottom: rem(50px);
	
				@include breakpoint(giant) {
					font-size: rem(60px);
				}	
				@include breakpoint(huge) {
					font-size: rem(79px);
				}
			}

			.partner-gallery {
				align-items: center;
				justify-content: center;

				@include breakpoint(giant,max) {
					flex-wrap: nowrap;
					overflow-x: scroll;
					justify-content: start;
				}

				.cms-gallery-image.cms-el-col {
					margin-bottom: rem($base-line-height);

					figure {
						padding: rem($base-gap*2) ($base-gap);
						border: rem(1px) solid rgba($medium, .5);
						display: flex;
						justify-content: center;
						align-items: center;
						
						a {
							margin-bottom: 0;
							width: auto;

							&:before {
								display: none;
							}
						}

						img {
							margin-bottom: 0;
							max-height: rem(150px);
						}
					}
				}
			}

			.icon-col {
				text-align: center;
				color: rgba($dark, .8);

				i {
					font-size: rem(24px);
					vertical-align: middle;
					margin-right: rem(5px);
				}

				@include breakpoint(giant) {
					display: none;
				}

				span {
					font-size: rem(14px);
				}
			}
		}
	}
	.gallery-section {

		.cms-gallery {
			padding-top: rem(30px);

			.cms-el-col {
				padding: 0;

				figure {
					a {
						margin-bottom: 0;
					}

					img {
						margin-bottom: 0;
						filter: grayscale(1);
						transition: filter 0.3s ease;
	
						&:hover,
						&:focus {
							filter: grayscale(0);
						}
					}
				}
			}
		}
	}

	.standorte {
		padding-bottom: rem(80px);

		@include breakpoint(medium) {
			padding-bottom: rem(110px);
		}
		@include breakpoint(large) {
			padding-bottom: rem(160px);
		}
		
		.h2 {
			font-size: rem(35px);
			line-height: 1.05em;
			font-weight: bold;
			color: $primary;
			text-align: center;
			margin-bottom: rem($base-gap * 2);
			font-family: $display-font;

			@include breakpoint(small) {
				font-size: rem(45px);
			}
			@include breakpoint(giant) {
				font-size: rem(55px);
				margin-bottom: rem(45px);
			}
			@include breakpoint(huge) {
				font-size: rem(65px);
			}
		}

		.row {
			.col {
				.row {
					.col {
						@include breakpoint(medium) {
							display: flex;
							align-items: stretch;
						}

						@include breakpoint(large) {
							padding: 0 rem($base-gap/2);
						}

						@include breakpoint(giant) {
							padding: 0 rem($base-gap);
						}

						&:not(:last-child) {
							margin-bottom: rem($base-line-height);

							@include breakpoint(medium) {
								margin-bottom: rem($base-gap*2);
							}

							@include breakpoint(large) {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.inner {
			background: lighten($medium, 2.5%);
			padding: rem($base-gap*2) rem($base-gap);
			border-radius: rem(15px);
			width: 100%;

			@include breakpoint(large) {
				font-size: rem(15px);
			}

			@include breakpoint(giant) {
				font-size: rem(16px);
			}

			@include breakpoint(huge) {
				font-size: rem($base-font-size);
				padding: rem(50px) rem($base-gap*2);
			}

			.h3 {
				font-size: rem($h3-size);

				@include breakpoint(huge) {
					font-size: rem($h3-size + 4px);
				}
			}

			dl {
				margin: 0 auto rem($base-line-height);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				max-width: rem(200px);

				@include breakpoint(huge) {
					max-width: rem(215px);
				}

				dd, dt {
					display: inline;
					padding: 0;
					background-color: transparent;
				}

				dt {
					margin-right: rem(5px);

					&:last-of-type {
						margin-top: 0;
					}
				}

				dd {
					&:after {
						content: 'Uhr';
						margin-left: rem(5px);
					}
				}
			}

			.cms-article {
				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			.cms-map {
				height: rem(250px);

				* {
					height: rem(250px);
				}

				iframe {
					height: rem(250px);
					border: rem(2px) solid darken($medium, 1%);
					border-radius: rem(15px);
				}
			}
		}	
	}

	.parallax-area {
		position: relative;
		padding: rem(80px) 0;
		
		@include breakpoint(large) {
			padding: rem(80px) 0 rem(200px) 0;
		}
		@include breakpoint(giant) {
			padding: rem(80px) 0 rem(270px) 0;
		}

		.parallax-img-box {
			margin-top: rem(-350px);
			max-width: calc(100% - #{rem($base-gap * 2)});

			body.cms-liveeditor-is-opened & {
				margin-top: 0;
				transform: translate(0, 0);
				position: static;
				left: 0;
				top: 0;
			}
		}

		.claim-box {
			display: flex;
			align-items: center;
			flex-direction: column;

			.claim {
				font-size: rem(35px);
				line-height: 1.05em;
				font-weight: bold;
				color: $primary;
				text-align: center;
				margin-bottom: rem($base-gap * 2);

				@include breakpoint(small) {
					font-size: rem(45px);
				}
				@include breakpoint(giant) {
					font-size: rem(55px);
					margin-bottom: rem(45px);
				}
				@include breakpoint(huge) {
					font-size: rem(65px);
				}

				span {
					display: block;
					padding-top: rem(10px);
					font-size: rem(25px);
					font-weight: 400;
					line-height: 1.2em;

					@include breakpoint(small) {
						font-size: rem(30px);
						padding-top: rem(23px);
					}
					@include breakpoint(huge) {
						font-size: rem(35px);
					}
				}
			}
		}
		.col1 {

			.tools {
				body:not(.cms-liveeditor-is-opened) & {
					display: none;

					@include breakpoint(large) {
						display: block;
						height: auto;
						width: auto;
					}

					figure {
						img {
							width: rem(400px);

							@include breakpoint(huge) {
								width: rem(500px);
							}
						}
					}
				}
			}
		}

		.col2 {
			.dryer {
				body:not(.cms-liveeditor-is-opened) & {
					display: none;

					@include breakpoint(large) {
						display: block;
						height: auto;
						width: auto;
					}


					figure {
						img {
							width: rem(400px);

							@include breakpoint(huge) {
								width: rem(500px);
							}
						}
					}
				}
			}
		}
		.list-title {
			font-family: $display-font;
			color: $primary;
			font-size: rem(40px);
			display: inline-block;
			margin-bottom: rem(55px);
			padding-left: rem(36px);
			margin-top: rem($base-gap * 2);

			@include breakpoint(tiny) {
				margin-top: 0;
				padding-left: rem(122px);
			}
			@include breakpoint(huge) {
				font-size: rem(50px);
			}
			@include breakpoint(full) {
				font-size: rem(60px);
			}
		}
		.special-list {
			color: $primary;
			font-size: rem(15px);
			font-weight: bold;
			position: relative;
			padding-left: rem(100px);
			
			@include breakpoint(tiny) {
				padding-left: rem(132px);
			}

			&.one,
			&.two {
				&:after {
					font-size: rem(60px);
					font-family: $display-font;	
					font-weight: normal;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);

					@include breakpoint(tiny) {
						font-size: rem(80px);
					}	
					@include breakpoint(giant) {
						font-size: rem(120px);
					}			
				}
			}
			&.one {
				&:after {
					content: '1';
				}
			}
			&.two {

				@include breakpoint(giant) {
					width: rem(500px);
				}

				&:after {
					content: '2';
				}
			}

			&:before {
				content: '';
				width: rem(3px);
				display: block;
				position: absolute;
				top: rem(-$base-gap);
				left: rem(60px);
				bottom: rem(-$base-gap);
				background: image-url("layout/dots-list.png") repeat-y center center;

				@include breakpoint(tiny) {
					left: rem(90px);
				}
			}

			li {
				position: relative;

				&:not(:last-child) {
					margin-bottom: rem(13px);
				}

				&:before {
					content: '';
					width: rem(15px);
					height: rem(2px);
					background-color: $primary;
					display: block;
					position: absolute;
					top: rem(13px);
					left: rem(-10px );					
				}
			}
		}
	}

	.section-separator {
		min-height: rem(550px);
		padding: 0 0 rem(105px) 0; 		
    	background-position: 0 0;
    	background-repeat: no-repeat;
    	background-size: cover;
    	
    	body:not(.is-touch) & {
    		@include breakpoint(giant) {
		    	background-attachment: fixed; 
		    }
    	}

    	@include breakpoint(tiny) {
			min-height: rem(400px);
    	}    	
    	@include breakpoint(huge) {
			min-height: rem(850px);
    	}

		&.lazy-loaded {
	    	@include responsive-image('index/separator.jpg', ( medium, giant), true);
			background-position: center;
			filter: grayscale(1);
	    }

	    .row {
	    	height: 100%;
	    }

	    .separator-text {
	    	display: inline-block;
	    	color: $light;
	    	font-size: rem(20px);
	    	line-height: 1.3em;
	    	font-weight: bold;
	    	padding-top: rem(350px);
	    	text-align: center;
	    	width: 100%;

			@include breakpoint(tiny) {	    	
	    		padding-top: rem(450px);
	    		font-size: rem(25px);
	    	}
	    	@include breakpoint(small) {
				font-size: rem(30px);
	    	}
	    	@include breakpoint(medium) {
				font-size: rem(35px);
	    	}
	    	@include breakpoint(large) {
	    		text-align: left;
	    		line-height: 1.2em;
	    	}
	    	@include breakpoint(huge) {
	    		font-size: rem(45px);
	    		padding-top: rem(635px);
	    	}

			span {
				font-weight: 300;

				@include breakpoint(large) {
					display: block;

					&:before {
						content: '-';
						margin-right: rem(5px);
					}
				}
			}

	    	body.cms-liveeditor-is-opened & {
	    		color: $primary;
	    	}
	    }
	    .branding {
	    	position: absolute;
	    	bottom: rem(100px);

	    	@include breakpoint(small) {
	    		bottom: rem(100px);
	    	}

	    	@include breakpoint(large, max) {
	    		left: 50%;
	    		transform: translateX(-50%);
	    	}
	        	
	    	@include breakpoint( large) {
	    		right: 8rem;
	    	}

	    	body.cms-liveeditor-is-opened & {
	    		right: 0;
	    		bottom: 0;
	    	}

	    	img {
	    		width: rem(300px);

	    		@include breakpoint(giant) {
		    		width: auto;
		    	}
	    	}
	    }
	}

	.section-scissors {
		padding: rem(80px) 0;
		
		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}
		
		@include breakpoint(giant) {
			padding: rem(50px) 0;
		}

		> .row {
			@include breakpoint(large) {
				align-items: center;
			}
		}

		.img-col {
			@include breakpoint(large,max) {
				display: none;
			}


			figure {
				text-align: center;

				img {
					max-height: rem(500px);
					width: auto;

					@include breakpoint(huge) {
						max-height: rem(450px);
					} 
				}
			}
		}
	}

	.section-team {
		text-align: center;
		background-color: $medium;
		padding: rem(80px) 0;
		
		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}
		@include breakpoint(large) {
			text-align: left;
		}
		@include breakpoint(giant) {
			padding: rem(130px) 0;
		}

		.text-box {
			text-align: center;

			@include breakpoint(large) {
				text-align: left;
			}

			.team-title {
				color: $primary;
				display: block;
				font-size: rem(50px);
				line-height: 1em;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: rem(50px);

				@include breakpoint(giant) {
					font-size: rem(60px);
				}	
				@include breakpoint(full) {
					font-size: rem(79px);
				}

				span {
					display: block;
					font-family: $display-font;
					font-weight: 400;
					text-transform: none;	
					text-indent: rem(60px); 
				}

			}

			p {
				&:last-child {
					margin-bottom: rem(50px);
				}
			}
		}

		.btn {
			&:after {
				border:rem(6px) solid $medium;
			}
		}
	}

	// STANDORTE

	.maps {
		margin-top: rem($base-gap*2);

		.col {
			&:not(:last-child) {
				@include breakpoint(giant,max) {
					margin-bottom: rem($base-gap*2);
				}				
			}
		}
	}

	// AUßENDIENST

	.team-ctn {
		display: flex;

		.col {
			margin-bottom: rem($base-line-height);

			@include breakpoint(extra-small) {
				display: flex;
				align-items: stretch;
			}

			&[data-element-type="loopPlaceholder"] {
				display: block;
			}
		}

		.team-member {
			background: $medium;
			text-align: center;
			padding: rem($base-gap*2) rem($base-gap);
			font-size: 90%;
			width: 100%;
			border-radius: rem(50px);

			@include breakpoint(tiny) {
				font-size: 100%;
			}

			@include breakpoint(extra-small) {
				font-size: 90%;
			}

			@include breakpoint(large) {
				font-size: 100%;
				padding: rem($base-gap*2) rem($base-gap*1.5)
			}

			@include breakpoint(huge) {
				padding: rem(50px) rem($base-gap*2);
			}
		}
	}

	// MARKEN

	.logo-ctn {
		display: flex;
		align-items: center;

		.col {
			margin-bottom: rem($base-gap*2);

			@include breakpoint(small) {
				margin-bottom: rem(50px);
			}

			@include breakpoint(huge) {
				width: 20%;
				flex-basis: 20%;
				max-width: 20%;

				body.cms-liveeditor-is-opened & {
					width: 25% !important;
					flex-basis: 25% !important;
					max-width: 25% !important;
				}
 			}

			.img-ctn {
				body:not(.cms-liveeditor-is-opened) & {
					position: relative;
					height: rem(100px);
					text-align: center;

					@include breakpoint(extra-small) {
						height: rem(85px);
						max-width: rem(200px);
						margin: 0 auto;
					}

					figure {
						text-align: center;

						img {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	// KATALOGE

	.catalogue-ctn {
		display: flex;

		.col {
			margin-bottom: rem($base-gap*2);

			@include breakpoint(extra-small) {
				display: flex;
				align-items: stretch;
			}

			&[data-element-type="loopPlaceholder"] {
				display: block;
			}

			.inner {
				background: $medium;
				padding: rem($base-gap*2);
				border-radius: rem(50px);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				transition: background .3s ease;

				@include breakpoint(tiny) {
					padding: rem(50px);
				}

				@include breakpoint(extra-small) {
					padding: rem($base-gap*2);
				}

				@include breakpoint(huge) {
					padding: rem(50px);
				}

				figure {
					img {
						margin-bottom: 0;
						transform: scale(1);
						transition: transform .3s ease;
					}
				}

				&:hover, &:focus, &:active {
					background: darken($medium, 5%);

					figure {
						img {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}

	// VERANSTALTUNGEN
		.news-box {
			background: rgba($medium, .7);
			padding: rem($base-gap*2) rem($base-gap) calc(#{rem($base-gap*2)} - #{rem($base-line-height)});
			border-radius: rem(50px);

			@include breakpoint(tiny) {
				padding: rem($base-gap*2) rem($base-gap*1.5) calc(#{rem($base-gap*2)} - #{rem($base-line-height)});
			}

			@include breakpoint(small) {
				padding: rem($base-gap*2.5) rem($base-gap*2) calc(#{rem($base-gap*2.5)} - #{rem($base-line-height)});
			}

			@include breakpoint(giant) {
				padding: rem(55px) rem($base-gap*2.5) calc(#{rem(55px)} - #{rem($base-line-height)});
			}
			
			&:not(:last-of-type) {
				margin-bottom: rem($base-gap*2);
			}
		}

		.werkstatt {
			div {
				&:first-of-type {
					@include breakpoint(small,max) {
						order: 2;
					}
				}
			}
			iframe {
				max-height: 360px;
			}
		}

		.adress-border {
			@include breakpoint(small) {
				margin-top: 2rem;
			}

			&:before {
				content:"";
				width: 80px;
				background-color: $dark;
				height: 3px;
				display: block;
				top: 0px;
				left: 0px;
				margin-bottom: 0.5rem;
				border-radius: 25px;
			}
		}

	// STELLENANGEBOTE

	.job-ctn {
		.job-box {
			background: rgba($medium, .7);
			padding: rem($base-gap*2) rem($base-gap);
			border-radius: rem(50px);

			@include breakpoint(tiny) {
				padding: rem($base-gap*2) rem($base-gap*1.5);
			}

			@include breakpoint(small) {
				padding: rem($base-gap*2.5) rem($base-gap*2);
			}

			@include breakpoint(large) {
				padding: rem($base-gap*2.5) rem($base-gap*2) calc(#{rem($base-gap*2.5)} - #{rem($base-line-height)});
			}

			@include breakpoint(giant) {
				padding: rem(55px) rem($base-gap*2.5) calc(#{rem(55px)} - #{rem($base-line-height)});
			}
		}
	}
}


#privacy-policy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	padding-top: rem(80px);
	padding-bottom: rem(60px);

	@include breakpoint(medium) {
		padding-bottom: rem(40px);
		padding-top: rem(100px);
	}
	@include breakpoint(large) {		
		padding-bottom: rem(120px);
	}
	@include breakpoint(giant) {
		padding-top: rem(160px);
		padding-bottom: rem(80px);
	}
	@include breakpoint(huge) {
		padding-bottom: rem(60px);
	}

	body.index & {
		padding-top: 0;
	}

	.footer-swiper {
		position: relative;
		height: rem(250px);
		margin-bottom: rem(80px);

		@include breakpoint(medium) {
			margin-bottom: rem(100px);
		}

		@include breakpoint(full) {
			height: rem(300px);
		}

		body.impressum &, body.datenschutzerklaerung &, body.standorte & {
			display: none;
		}

		body:not(.cms-template-edit) & {
			.swiper-wrapper {
				display: flex;

				.swiper-slide {
					display: flex;

					figure {
						display: flex;

						a {
							display: flex;
							margin-bottom: 0;
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: top;
							
							@include breakpoint(tiny,max) {
								object-position: 0 rem(-40px);
							}
						}
					}
				}
			}
		}
	}

	.claim-box {
		text-align: center;
		margin-bottom: rem(80px);
		
		@include breakpoint(medium) {
			margin-bottom: rem(100px);
		}

		body.standorte & {
			display: none;
		}

		.cms-title {
			display: block;
			color: $darkest;
			font-size: rem(30px);
			font-weight: bold;
			line-height: 1.25;
			text-transform: uppercase;
			margin-bottom: rem(40px);

			@include breakpoint(medium) {
				font-size: rem(50px);
			}
			@include breakpoint(giant) {
				font-size: rem(60px);
			}	
			@include breakpoint(huge) {
				font-size: rem(79px);
			}

			span {
				display: block;
				font-family: $display-font;
				font-weight: normal;
				font-size: rem(50px);
				position: relative;
				text-transform: none; 
				position: relative;

				@include breakpoint(medium) {
					font-size: rem(70px);
				}
				@include breakpoint(giant) {
					font-size: rem(90px);
				}	
				@include breakpoint(huge) {
					font-size: rem(113px);
				}

				&:before {
					content: '';
					width: rem(51px);
					height: rem(55px);
					display: block;
					position: absolute;
					top: rem(-22px);
					right: rem(-80px);
					animation: heartbeat 1.6s linear infinite;
				}
			}
		}
	}

	.info {
		background-color: $medium; 
		color: $dark;
		font-size: rem(17px);
		padding: rem(50px) 0;

		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}
		@include breakpoint(giant) {
			box-shadow: inset 0 rem(70px) 0 0 $light, inset 0 rem(-70px) 0 0 $light;
			padding: 0;
		}		
		@include breakpoint(huge) {			
			font-size: rem($base-font-size);
			box-shadow: inset 0 rem(100px) 0 0 $light, inset 0 rem(-100px) 0 0 $light;
		}
		
		.box {
			text-align: center;

			@include breakpoint(large) {
				text-align: left;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.claim {
				font-weight: 700;
				position: relative;
				width: fit-content;
				margin: 0 auto rem($base-line-height*2);

				@include breakpoint(large) {
					margin: 0 0 rem($base-line-height*2);
				}

				.h2 {
					margin-bottom: 0;

					@include breakpoint(large) {
						font-size: rem($h2-size - 3px);
					}

					@include breakpoint(giant) {
						font-size: rem($h2-size);
					}
				}

				.small {
					font-family: $display-font;
					position: absolute;
					right: rem(-15px);
					bottom: rem(-$base-line-height);
					font-size: rem($h3-size);

					@include breakpoint(tiny) {
						right: rem(-30px);
					}

					@include breakpoint(medium) {
						font-size: rem($h2-size);
					}

					@include breakpoint(large) {
						right: rem(-15px);
					}

					@include breakpoint(giant) {
						right: rem(-30px);
					}
				}
			}

			ul.company-data {
				margin-bottom: rem($base-line-height);

				@include breakpoint(giant) {
					display: flex;
					justify-content: space-between;
				}

				@include breakpoint(huge) {					
					margin-bottom: rem(40px);					
				}

				li {
					&:not(:last-child) {
						@include breakpoint(giant, max) {
							margin-bottom: rem(10px);
						}
					
						@include breakpoint(giant) {
							margin-right: rem(75px);
						}

						@include breakpoint(full) {
							margin-right: rem(100px);
						}
					}
				}
			}

			.openings {
				// margin-bottom: rem($base-line-height);
				position: relative;
				cursor: pointer;
				width: fit-content;
				margin: 0 auto rem($base-line-height);

				@include breakpoint(large) {					
					margin-left: 0;	
					margin-right: 0;					
				}

				@include breakpoint(huge) {					
					margin-bottom: rem(40px);					
				}

				span {
					position: relative;
				}

				.inner {
					position: absolute;
					background: $light;
					width: auto;
					min-width: rem(250px);
					bottom: 100%;
					left: 0;
					height: auto;
					padding: rem($base-gap);
					visibility: hidden;
					opacity: 0;
					transition: visibility .3s ease-in-out, opacity .3s ease-in-out;
					pointer-events: none;
					box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1), 0 0 0 2px #fff, 0.3em 0.3em 1em rgba(0,0,0,0.3);
					z-index: 100;
					text-align: center;

					@include breakpoint(huge) {
						min-width: rem(300px);
					}
				}

				dl {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					max-width: rem(215px);
					text-align: left;
					margin: 0 auto;

					dd, dt {
						display: inline;
					}

					dt {
						margin-right: rem(5px);
						font-weight: 700;
					}

					dd {
						&:after {
							content: 'Uhr';
							margin-left: rem(5px);
						}
					}
				}

				&:hover, &:focus {
					.inner {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.payment {
				font-size: 85%;
				line-height: 1.65;
			}
		}

		.cms-map {
			overflow: hidden; 
			position: relative;
			border-radius: rem(30px);
			width: 100%;
			height: rem(328px);
			margin-bottom: rem($base-gap * 2);
			z-index: 2;


			@include breakpoint(tiny) {
				height: rem(400px);
			}
			@include breakpoint(small) {				
				border-radius: rem(60px);
			}
			@include breakpoint(large) {
				margin-right: rem($base-gap * 2);
				margin-bottom: 0;
				flex-grow: 0;
    			flex-shrink: 0;
				width: rem(480px);
				height: rem(480px);
			}
			@include breakpoint(large, max) {
				margin: 0 auto rem($base-gap * 2) auto;
			}

			@include breakpoint(giant) {
				box-shadow: 0 0 0 1.5rem $light;
				margin-right: rem(50px);
			}
			@include breakpoint(huge) {
				width: rem(589px);
				height: rem(589px);
				box-shadow: 0 0 0 2rem $light;
				margin-right: rem(75px);
			}
			@include breakpoint(full) {
				margin-right: rem(82px);
			}

			> div {
				height: 100%;
				border-radius: rem(15px);

				@include breakpoint(small) {				
					border-radius: rem(30px);
				}
			}

			iframe {
				width: 100%;
				height: 100%;
				border-radius: rem(15px);
		
				@include breakpoint(small) {				
					border-radius: rem(30px);
				}
			}	
		}
	}
}


[data-aos] {
	@include breakpoint(huge, max) {
	    opacity: 1  !important;
	    transform: none  !important;
	}
}

// KEYFRAMES

@keyframes CustomFadeIn3 {
	0% {
		opacity: 0;
		transform: scale(.7, .7);

	}
	100% {
		opacity: .1;
		transform: scale(1, 1);
	}
}
@keyframes CustomFadeIn {
	0% {
		opacity: 0;
		margin-left: -80%;
	}
	100% {
		opacity: 1;
		margin-left: 0;
	}
}
@keyframes CustomFadeTop {
	0% {
		opacity: 0;
		transform: translateY(50%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes CustomMove1 {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		margin-left: 100px;
	}
}
@keyframes CustomMove2 {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		margin-left: 100px;
	}
}
@keyframes move {
	0% {
	    offset-distance: 0%;
	    opacity: 1;
	}
	100% {
	    offset-distance: 100%;
	    opacity: 0;
	}	
}
@keyframes zoomInUp {
	0% {
		animation-timing-function: cubic-bezier(.55,.055,.675,.19);
		opacity: 0;
		transform: scale3d(.1,.1,.1) translate3d(0,-1000px,0)
	}
	60% {
		animation-timing-function: cubic-bezier(.175,.885,.32,1);
		opacity: .1;
		transform: scale3d(.475,.475,.475) translate3d(0,60px,0)
	}
}	