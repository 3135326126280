// Use this for default Icomoon

@include font-face-V2(
	$font-name: "icomoon",
	$file-name: "icomoon",
	$types: (woff, ttf, svg, eot),
	$style: normal,
);

// Example font-face for Roboto
@include font-face-V2(
    $font-name: "Quicksand", 
    $file-name: "quicksand-v28-latin", 
    $weights: ("300", "400", "700"), 
    $types: (woff,woff2)
);
@include font-face-V2(
    $font-name: "Quentin", 
    $file-name: "quentin", 
    $weights: ("400"), 
    $types: (woff,woff2)
);