/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$image-path: "../images/" !default;

$base-font-size: 18px;

$base-line-height: 28px;

$h1-size: 65px;

$h2-size: 30px;

$h3-size: 24px;

$h4-size: 22px;

$h5-size: 18px;

$h6-size: 16px;

$main-font: Quicksand, sans-serif;

$display-font: "Quentin", sans-serif;

$icon-font: icomoon;

// Project colors

$primary: #1d1d1b;
$secondary: #385298;
$third: #52dee6;
$fourth: #432c3f;
$fifth: #c3b1c0;
$light: #fff;
$medium: #ebebeb;
$dark: #5d5d5d;
$darkest: #000;
$border: #bbb;
$alert: #ed4957;
$alert-dark: #de3d4b;

$base-gap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$column-count: $columnCount;

$base-border: 1px solid $border;

$base-box-shadow: 0px 5px 10px 0px $medium;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hide-text-direction: right;

$icon-map: (
	chair: "\e900", 
	nailpolish: "\e901", 
	scissors: "\e903", 
	whatsapp-white: "\e902", 
	gesture-phone-03: "\e9e3", 
	round-check: "\eaa6", 
	exclamation: "\e90d", 
	minus: "\e90c", 
	plus: "\e90a", 
	times: "\e909", 
	check-box: "\e957", 
	clock: "\e968", 
	envelope: "\e9b0", 
	gesture-phone-07: "\e9e7", 
	lens-plus: "\ea16", 
	angle-down: "\ea1d", 
	angle-left: "\ea1e", 
	angle-right: "\ea1f", 
	angle-up: "\ea21", 
	arrow-left: "\ea24", 
	arrow-right: "\ea2a", 
	paper-plane: "\ea6c", 
	phone: "\ea81", 
	sheet: "\eac3", 
	fax: "\e9b9", 
	play: "\ea29", 
	map-marker: "\ea3d", 
	people-info: "\ea7b", 
	external: "\e900", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	mini: (
		width: em(360px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	extra-small: (
		width: em(600px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full2: (
		width: em(1620px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	max: (
		width: em(1770px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
);

