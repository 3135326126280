.margin-bottom	{
	margin-bottom: rem($base-line-height);
}

.preload * {
    transition: none !important;
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animated-transform;
	appearance: none;
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem(16px);
	font-weight: 700;
	line-height: rem(22px);
	padding: rem(19px) rem(34px);
	text-align: center;
	text-decoration: none;
	font-family: $main-font;
	border-radius:rem(15px);
	text-transform: uppercase;
	position:relative;

	&:hover, &:focus  {
		background: $secondary;
		color: $light;
	}
	&:active {
		background: $secondary;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
	&.icon-btn{
		padding-right:rem(43px);

		&:after{
			content: map-get($icon-map, angle-right);
			font-family: $icon-font;
			display: block;
			position:absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem(33px);
			height: rem(33px);
			border-radius:100%;
			box-sizing:content-box;
			background: $secondary;
			top:-14px;
			right:-15px;
			z-index:1;
			border:rem(6px) solid $light;
			
		}

		&.contact {
			&:after {
				content: map-get($icon-map, envelope);
				font-family: $icon-font;
			}
		}

		&.route {
			&:after {
				content: map-get($icon-map, map-marker);
				font-family: $icon-font;
			}
		}
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hideText {
	@include hide-text();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right: 1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.non-fluid {
	width: auto !important;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

.rundesBild {
	img {
		border: 1rem $light solid;
		border-radius: 50%;
		aspect-ratio: 1/1;
		object-fit: cover;


		@include breakpoint(small) {
			border: 2rem $light solid;
		}
	}
}

/* 16:9 video resolutions */
.video-container {
	&:not(.video-tag) {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 44.05%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.google-maps {
	@extend .video-container;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
		$content: "default:" + $bpContext;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $gridMap {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.dev-mode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animated-transform {
	transition: 0.3s;
}

html.in-progress {
	pointer-events: none;
	&:before {
		content:"";
		width: 100vw;
		height: 100vh;
		background:rgba(0,0,0,0.5);
		position: fixed;
		top:0;
		left:0;
		z-index:99999998
	}
	&:after {
		content:"";
		position: fixed;
		top: 0;
		height: 0;
		background: url('/images/layout/loading.svg') no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index:99999999;
		filter:invert(100%);
	}
}

// cms-4 grid system classes
.row {
	//vertical alignment
	&.stretch {
		align-items: stretch;
	}
	&.top {
		align-items: flex-start;
	}
	&.middle {
		align-items: center;
	}
	&.bottom {
		align-items: flex-end;
	}
	//horizontal alignment
	&.start {
		justify-content: flex-start;
	}
	&.center {
		justify-content: center;
	}
	&.end {
		justify-content: flex-end;
	}
	&.around {
		justify-content: space-around;
	}
	&.between {
		justify-content: space-between;
	}
}
/* popup video */
.popUpContainer {
	body:not(.cms-page-edit) & {
	    top: 0;
	    left: 0;
	    width: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    height: 100vh;
	    overflow: hidden;
	    transition: opacity .3s, visibility .3s;
	    position: fixed;
	    visibility: visible;
	    opacity: 1;
	    z-index: 1000000;
	 
	    .popUpContent {
	        width: 100%;
	        max-height: 90vh;
	        max-width: calc(100% - #{rem(36px)}); // for the close label
	        overflow: hidden;
	        overflow-y: auto;
	 				@include breakpoint(medium) {
	             max-width: calc(100% - #{rem(110px)});
	        }
	        @include breakpoint(giant) {
	            max-width: 75%!important;
	        }
	 
	        @include breakpoint(full) {
	            max-width: 65%!important;
	        }
	    }
	}
	 
	label {
	    display:none;
	 
	    body:not(.cms-page-edit) & {
	        background: rgba(black, 0.8);
	        display: flex;
	        height: 100%;
	        justify-content: flex-end;
	        padding: rem($base-gap);
	        position: absolute;
	        right: 0;
	        top: 0;
	        width: 100%;
	        z-index: -1;
	 
	        i {
	            cursor: pointer;
	            display: block;
	            height: rem(35px);
	            width: rem(35px);
	            position: relative;
	            transition: background .3s;
	 
	            &:hover {
	                background: rgba(black, 0.8);
	 
	                &:before, &:after {
	                    background: white;
	                }
	            }
	 
	            &:before, &:after {
	                content: '';
	                display: block;
	                position: absolute;
	                top: 50%;
	                left: 50%;
	                width: rem(30px);
	                height: rem(2px);
	                background: #ddd;
	            }
	 
	            &:before {
	                transform: translate(-50%,-50%) rotate(45deg);
	            }
	 
	            &:after {
	                transform: translate(-50%,-50%) rotate(-45deg);
	            }
	        }
	    }
	}
}
 
input[id^="popUpToggled"] {
    display: none;
    &:not(:checked) {
        + {
	        .popUpContainer {
	            position: relative;
	            opacity: 0;
	            visibility: hidden;
	            z-index: -1000000;
	            transform:scale(0);
	            pointer-events: none;
	            width: 0;
	            height: 0;
	     	 }
    	}
	}
}

// Inner pages 
////////////////////////////
.area-light{
	background: $light;
}
.area-bg{
	background:$medium;
}
.area-center{
	text-align: center;
}
.innerStructure{
	padding:rem(80px) 0;
	@include breakpoint(large) {
       padding-top: rem(100px);
    }

}
.default-page{
	padding:rem(20px) 0 rem(40px) 0;
	@include breakpoint(medium) {
     padding:rem(80px) 0;
  }
	@include breakpoint(large) {
     padding-top: rem(100px);
  }
}

// Über uns
.inner-area1{
	padding:0 0 rem(80px) 0;
	@include breakpoint(medium) {
		padding:0 0 rem(110px) 0;
	}
	@include breakpoint(large) {
		padding:rem(110px) 0 rem(160px) 0;
	}
	.video-box{
		padding-top:rem(30px);
	}
}

.area1{
	padding:rem(80px) 0;
	@include breakpoint(large) {
       padding:rem(140px) 0;
   }
   @include breakpoint(giant) {
       padding:rem(200px) 0;
   }
	+ .area2{
		padding-top: rem(80px);
		@include breakpoint(large) {
      padding-top: rem(140px);
    }
	}
}

.container2{
	padding: rem(80px) 0;
	@include breakpoint(medium) {
    	padding: rem(100px) 0;
  }
	@include breakpoint(large) {
    	padding: rem(140px) 0;
  }
}
.highlight-title{
	font-weight: 700;
	font-size: rem(20px);
	color:$primary;
	@include breakpoint(medium) {
		font-size: rem(25px);
	}
}



// Gallery
.gallery-area{
	padding-bottom: calc(80px - 2rem);
	@include breakpoint(medium) {
	     padding-bottom: calc(100px - 2rem);
	}
	+ .gallery-area{
		padding-top: rem(80px);
		@include breakpoint(medium) {
	      padding-top: rem(100px);
	    }
	}
	&:nth-child(2n+1){
		background: $medium;
	}
		.cms-el-row figure, .cms-el-row a, .cms-el-row img {
		border-radius: 100%;
		display: block;
 	}
}

.modalGallery.center {
	justify-content: center;
}

.object-fit {
	body:not(.cms-liveeditor-is-opened) & {
		@include breakpoint(large) {
			.col {
				align-items: stretch;
				display: flex;
	
				&.img {
					position: relative;
	
					figure {
						width: 100%;
						display: flex;
	
						a {
							margin-bottom: 0;
							display: flex;
						}
	
						img {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							object-fit: cover;
						}
					}
				}
	
				p, ul {
					&:last-of-type {
						margin-bottom: 0 !important;
					}

					&.margin {
						margin-bottom: rem($base-line-height) !important;
					}
				}
			}
		}	
	}
}
