// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $primary; // Navigation Background
$nav-color: $light; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	padding: .8em rem($base-gap);
	text-decoration: none; 
	color: $nav-color;
	font-weight: 300;

	@include breakpoint($break) {
		padding: 0;
		color: $light;
		font-size: rem(20px);
	}
	@include breakpoint(giant) {
		font-size: rem(25px);	
	}
}
%button-hover {
	// Link Hover
	color: $light;
	background-color: $secondary;

	@include breakpoint($break) {
		color: lighten($secondary,20%);
		background-color: transparent;
	}
}
%button-active {
	// Link Active
	color: $light;
	background-color: $secondary;

	@include breakpoint($break) {
		font-weight: bold;
		color: $light;
		background-color: transparent;
	}
}
%prev-icon {
	// Icon to previus Submenu
	//font-family: $icon-font;
	//content: map-get($icon-map, angle-right);
	transform: rotateY(180deg);
	content: '';
	width: rem(9px);
	height: rem(12px);
	display: block;
	background: image-url("layout/icons/icon-arrow.svg") no-repeat center center;

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	//font-family: $icon-font;
	//content: map-get($icon-map, angle-right);
	content: '';
	width: rem(9px);
	height: rem(12px);
	display: block;
	background: image-url("layout/icons/icon-arrow.svg") no-repeat center center;

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 50px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: $third; // background-color
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background-color: $nav-bg;
	z-index: 9999;
	padding-bottom: rem(150px);


	nav {

		.col {
			padding: 0;
		}
		@include ul {
			&.navi {
				li {
					border-bottom: 1px solid rgba($light, 0.2);
					
					@include nav-buttons {
						@include nav-button;
					}
					@include has-sub {
						@include sub {
							background: $nav-bg;
							padding-bottom: rem(200px);

							&:before {
								padding: .8em rem($base-gap);
								background-color: $third;
								color: $light;
								font-size: rem(22px);
								font-weight: 300;
							}
						}
					}
					@include nav-back {
					}

					@include breakpoint($break,max) {
						&.has-sub {
							&.unternehmen {
								.sub {
									&:before {
										content: 'Unternehmen';
									}
								}
							}
	
							&.friseurbedarf {
								.sub {
									&:before {
										content: 'Friseurbedarf';
									}
								}
							}
	
							&.standorte {
								.sub {
									&:before {
										content: 'Standorte';
									}
								}
							}
						}
					}
				}
			}
		}
	}

}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		height: 100vh;
		min-height: 100%;
		background-color: $primary;
		z-index: 1001;
		overflow-y: auto;

		nav {
			height: 100%;

			.col {
				padding: 0 rem($base-gap);
			}

			ul {
				&.navi {
					margin-bottom: rem(80px);

					@include breakpoint(full) {
						margin-bottom: rem(120px);
					}
					li {
						border-bottom: transparent;

						&.has-sub {
							.sub {
								opacity: 1;
								visibility: visible;
								position: relative;
								top: 0;
								background: transparent;
								background: image-url('layout/dots-separator.png') 0 0 repeat-y;
								padding: rem(6px) 0 rem(6px) rem(36px);
								margin: rem(18px) 0 0 rem(5px);

								@include breakpoint(giant) {
									padding: rem(6px) 0 rem(6px) rem(50px);
									margin: rem(40px) 0 0 rem(5px);
								}

								li {
									&:not(:last-child) {
										margin-bottom: rem(18px);

										@include breakpoint(giant) {
											margin-bottom: rem(30px);
										}
										@include breakpoint(full) {
											margin-bottom: rem(43px);
										}
									}
								}
							}
						}
						&.nav-back {
							display: none;
						}
					}
				}
			}
		}
	}
}


#navigation {

	.navi-box {

		.cms-navi {
			&:last-child {
				.navi {
					
					li {
						&.index {
							@include breakpoint(large, max) {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(large) {

		.navi-main {
			li {
				&.has-sub {
					.sub-toggler {
						&:after {
							display: none;
						}
					}
				}
			}
		}

		nav {
			> div {
				height: 100%;
			}
		}

		.box {
			height: 100%;
			padding-top: rem($base-gap*2);

			> .row {
				height: 100%;

				.box-col {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin-bottom: rem($base-gap * 4);
				}
			}
		}

		.navi-box {
			display: flex;
			justify-content: space-between;

			body.cms-template-edit & {
				display: block;
			}

			.cms-navi {
				&:first-child {
					padding-top: rem(28px);
					
					.navi.navi-main {
						// flex-wrap: wrap;
						display: block;
					}

					li {
						&.index {
							display: none;
						}

						&:not(:last-of-type) {
							margin-bottom: rem(18px);

							@include breakpoint(giant) {
								margin-bottom: rem(30px);
							}

							@include breakpoint(full) {
								margin-bottom: rem(43px);
							} 
						}

						&.has-sub {
							span {
								display: none;
							}

							.sub {
								li.unternehmen {
									position: absolute;
									top: rem(-44px);
									left: rem(-5px);

									@include breakpoint(giant) {
										top: rem(-68px);
									}

									a {
										font-size: rem(25px);

										@include breakpoint(giant) {
											font-size: rem(30px);
										}
										@include breakpoint(full) {
											font-size: rem(35px);									
										}
									}

									&.active {
										a {
											font-weight: 700;
										}
									}
								}
							}
						}
					}

				}
				&:nth-child(2) {
					padding-top: rem(28px);
					.navi {
						display: block;

						li {
							&.has-sub {
								span {
									display: none;
								}
	
								.sub {
									li.friseurbedarf {
										position: absolute;
										top: rem(-44px);
										left: rem(-5px);
	
										@include breakpoint(giant) {
											top: rem(-68px);
										}
	
										a {
											font-size: rem(25px);
	
											@include breakpoint(giant) {
												font-size: rem(30px);
											}
											@include breakpoint(full) {
												font-size: rem(35px);									
											}
										}

										&.active {
											a {
												font-weight: 700;
											}
										}
									}
								}
							}
						}
					}
				}
			
				&:last-child {
					.navi {
						display: block;
					
						li {
							&:not(:last-child) {
								margin-bottom: rem(18px);

								@include breakpoint(giant) {
									margin-bottom: rem(30px);
								}
			
							}

							&.has-sub {
								span {
									display: none;
								}
	
								.sub {
									margin-top: rem(62px);

									@include breakpoint(giant) {
										margin-top: rem(98px);
									}

									li.standorte {
										position: absolute;
										top: rem(-44px);
										left: rem(-5px);
	
										@include breakpoint(giant) {
											top: rem(-68px);
										}

										&.active {
											a {
												font-weight: 700;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.branding {
		position: relative;
		top: rem(24px);
		display: inline-block;
		margin-bottom: rem(36px);
		display: none;

		@include breakpoint(large, max) {
			display: none;
		}

		img {
			max-width: rem(300px);
			height: auto;
		}
	}
	.title {
		font-family: $display-font;
		color: darken($dark, 15%);
		font-size: rem(75px);
		line-height: 1.3em;
		display: inline-block;
		margin-bottom: rem(0px);

		@include breakpoint(large, max) {
			display: none;
		}

		@include breakpoint(giant) {
			font-size: rem(95px);
		}
		@include breakpoint(full) {
			font-size: rem(115px);
		}
	}

	.company-info {
		color: darken($medium, 20%);
		padding-bottom: rem($base-gap*2);

		ul {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 90%;

			@include breakpoint(giant) {
				font-size: 100%;
			}

			a.email {
				color: lighten($secondary, 10%);
				&:hover,
				&:focus,
				&.active {
					opacity: .8;
				}
			}

			.dist {
				display: inline-block;
				width: rem(75px);
			}

			li {
				&:not(:last-child) {
					@include breakpoint(large, max) {
						display: none;
					}
				}
			}

			dl {
				display: flex;
				flex-wrap: wrap;
				max-width: rem(250px);

				dt {
					min-width: rem(75px);
				}

				dd {
					&:after {
						content: 'Uhr';
						margin-left: rem(5px);
					}
				}
			}

			.socialBlock {
				display: flex;

				@include breakpoint(large, max) {
					padding: rem($base-gap);
					margin-bottom: rem(80px);
				}

				a {
					display: block;
					width: rem(39px);
					height: rem(39px);
					margin-right: rem(22px);

					&:hover,
					&:focus,
					&.active {
						&:before {
							opacity: 0.7;
						}
					}
					
					span {
						display: none;
					}

					&:before {
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						transition: 0.3s;
					}

					&.whatsapp {
						&:before {
							content: map-get($icon-map, whatsapp-white);
							font-family: $icon-font;
							font-size: rem(30px);
							color: $light;

							@include breakpoint(giant) {
								font-size: rem(40px);
							}
						}
					}
				}
			}
		}
	}
}


// Quickbar Styles
////
#quickbar {
	background-color: $primary;
	color: $bar-color;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 9998;

	body.cms-template-edit,
	body.cms-page-edit & {
		display: none;
	}

	@include breakpoint($break) {
		background-color: transparent; 
		display: flex;
		padding: 0;
		width: rem(150px);
		height: rem(115px);
		right: 0;
		left: auto;
		z-index: 1003;

		li:first-child {
			display: none;
		}
	}
	li {
		&:last-child {
			display: flex;
			align-items: center;

			span {
				text-transform: uppercase;
				font-size: rem(14px);
				margin-right: rem(5px);
				line-height: 0;
			}
		}

		@include breakpoint(large) {
			&:last-child {			
				position: fixed;
				top: rem(56px);
				right: rem($base-gap);
				z-index: 10;

				@include breakpoint(cut) {
					right: rem(90px);
				}

				span {
					font-size: rem(18px);
					margin-right: rem(8px);
					color: $primary;
				}

				&:after {
					content: '';
					width: rem(15px);
					height: rem(15px);
					display: block;
					background-color: $secondary;
					border-radius: 100%;
					position: absolute;
					top: rem(-8px);
					right: rem(-8px);
					z-index: 2;
					transition: 0.3s;
				}

				&:hover,
				&:focus,
				&.active {
					&:after {
						background-color: $fifth;
					}
				}


				label[for="navi-toggled"] {		
					width: rem(52px);
					height: rem(55px);
					background-color: $primary;	
					border-radius: rem(8px);
					background: radial-gradient(22px 22px at 96.5% 2px, rgba($light, 0) 10.5px, rgba($primary, 1) 11.5px); 

					span {
						
						&,
						&:before,
						&:after {
							background: $light;						
						}					
					}
				}
			}
		}
	}
	
}
#navi-toggled {

	@include breakpoint(large) {

		&:checked {
			~ #quickbar {
				li {
					&:last-child {

						&:before {
							background-color: $third;
						}

						label[for="navi-toggled"] {
							background-color: $light;
							background: radial-gradient(22px 22px at 96.5% 2px, rgba($light, 0) 10.5px, rgba($light, 1) 11.5px); 

							span {
						
								&,
								&:before,
								&:after {
									background: $primary;						
								}					
							}
						}
					}				
				}
			}
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

.navi-add {	
	position: relative;
	padding-top: rem(70px);

	@include breakpoint(medium) {
		display: flex;
		justify-content: center;
		padding-top: rem(36px);
	}
	@include breakpoint(giant) {
		top: rem(-36px);
		padding-top: 0;
	}
	@include breakpoint(giant) {
		justify-content: flex-end;
	}	
	@include breakpoint(huge) {
		top: rem(-63px);
	}

	body.cms-template-edit & {
		top: 0 !important;
	}

	li {
		@include breakpoint(medium, max) {
			border-bottom: 1px solid $medium;
			margin-left: 0 !important;

			&:last-child {
				border-bottom: transparent;
			}
		}

		&:not(:first-child) {
			margin-left: rem(30px);
			position: relative;

			@include breakpoint(huge) {
				margin-left: rem(50px);
			}
			@include breakpoint(full) {
				margin-left: rem(70px);
			}

			&:before {
				@include breakpoint(medium) {
					content: '/';
					position: absolute;
					top: 0;
					left: rem(-20px);
					color: #aaaaaa;
				}
				@include breakpoint(huge) {
					left: rem(-30px);
				}
				@include breakpoint(full) {
					left: rem(-37px);
				}
			}
		}

		a {
			font-weight: bold;
			color: $darkest;

			@include breakpoint(medium, max) {
				display: block;
				padding: rem(8px) 0;
				text-align: center;
			}

			&:hover,
			&:focus,
			&.active {
				color: $secondary;
			}
		}
	}
}

body.sitemap {
	.cms-navi {
		&:last-child {
			li.index {
				display: none;
			}
		}
	}
}