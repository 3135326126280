* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
	color:$primary;
	background: $fifth;
}
::-moz-selection {
	color:$primary;
	background: $fifth;
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem(26px);
	font-size: rem(16px);
	opacity: 1 !important;

	@include breakpoint(huge) {
		line-height: rem($base-line-height);
		font-size: rem($base-font-size);
	}
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}
body:not(.cms-liveeditor-is-opened) {
	.cms-container-el{
		min-height: 0 !important;
	}
}

p.openings{
	margin-bottom: 0;
}
/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
	color:$primary;

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	font-family: $main-font;
	font-size: rem($h1-size - 38px);
	line-height: 1.2em;
	font-weight: 900;
	color:$primary;
	margin-bottom: rem(20px);
	@include breakpoint(medium) {
	   font-size: rem($h1-size - 10px);
	   line-height: 1.2em;
	}
	@include breakpoint(large) {
	   font-size: rem($h1-size - 20px);
	   line-height: 1.2em;
	}
	@include breakpoint(giant - 5px) {
	   font-size: rem($h1-size);
	}
	@include breakpoint(huge) {
	   font-size: rem($h1-size);
	}
	span{
		display: block;
		font-size: rem(30px - 8px);
		line-height: rem(32px);
		font-weight: 300;
		@include breakpoint(medium) {
			font-size: rem(30px - 4px);
		    line-height: rem(35px);
		}
		@include breakpoint(large) {
			font-size: rem(28px);
		}
		@include breakpoint(giant) {
			font-size: rem(30px);
		}
	}
}

h2,
.h2 {
	font-size: rem($h2-size - 10px);
	line-height: 1.3em;

	@include breakpoint(medium) {
      font-size: rem($h2-size);
   }
}

h3,
.h3 {
	font-size: rem($h3-size - 4px);
	@include breakpoint(medium) {
      font-size: rem($h3-size);
   }
}

h4,
.h4 {
	font-size: rem($h4-size - 2px);
	@include breakpoint(medium) {
      font-size: rem($h4-size);
   }
}

h5,
.h5 {
	font-size: rem($h5-size);
}

h6,
.h6 {
	font-size: rem($h6-size);
}

/**
 * Links
 */
a {
	color: $secondary;
	word-wrap: break-word;
	text-decoration: none;
	@extend %animated-transform;

	&:hover,
	&:focus,
	&:active {
		color: $primary;
	}
	
	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;

		&:hover, &:focus, &:active {
			opacity: .8;
		}
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: rem($base-gap*2) 0;
	flex-basis: 100%;

	@include breakpoint(medium) {
		margin: rem(50px) 0;
	}

	@include breakpoint(giant) {
		margin: rem(75px) 0;
	}
}

ul,
ol {
	list-style: none;

	&.styled-list {
		li {
			padding-left: rem(15px);
			margin-bottom: rem(7px);

			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				text-indent: rem(-15px);
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}

	&.column-2 {
		column-count: 2;
	}
}

dl {
	&.styled-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($medium, 0.8);
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

body.standorte {
	dl {
		dd, dt {
			background: none;
			padding: 0;
		}

		dd {
			&:after {
				content: 'Uhr';
				margin-left: rem(5px);
			}
		}
	}
}

ol {
	&.styled-list
	{
		counter-reset: item;
	
		li {
			counter-increment: item;
	
			&:before {
				content: counters(item, ".") ". ";
			}
		}
	
		ol {
			margin-bottom: 0;
	
			li {
				&:before {
					content: counters(item, ".") " ";
					margin-right: rem(5px);
				}
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazy-loading;
	}
}

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) dotted $dark;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($base-gap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}

