/* default clickable thumbnail hover */

$image-selector: '.lightbox-zoom-image';
$gallery-selector: '.modalGallery';

a#{$image-selector},
#{$image-selector} a[href$=".jpeg" i],
#{$image-selector} a[href$=".jpg" i],
#{$image-selector} a[href$=".png" i],
#{$image-selector} a[href$=".gif" i],
#{$gallery-selector} a[href$=".jpeg" i],
#{$gallery-selector} a[href$=".jpg" i],
#{$gallery-selector} a[href$=".png" i],
#{$gallery-selector} a[href$=".gif" i] {
	@extend .margin-bottom ;
	display: table;
	position: relative;
	text-decoration: none;
	width: 100%;

	img {
		margin-bottom: 0 !important;
	}

	&[href*="qr"] {
		width: auto;

		&:before,
		&:after {
			content: none;
		}
	}

	&:before,
	&:after {
		@extend %animated-transform;
		pointer-events: none;
	}

	&:before {
		top: 0;
		color: rgba($light, 0.75);
		display: block;
		font-family: $icon-font;
		content: map-get($icon-map, lens-plus);
		font-size: 2rem;
		height: 2em;
		line-height: 1.8;
		position: absolute;
		right: 0;
		text-align: center;
		text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, 0.8);
		transform: translate3d(0, 0, 0);
		width: 2em;
		z-index: 1;
	}

	&:after {
		bottom: 0;
		content: "";
		display: block;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
	}

	&:hover,
	&:focus {
		&:before {
			top: 50%;
			font-size: 3rem;
			height: 1.5em;
			line-height: 1.5;
			right: 50%;
			transform: translate3d(50%, -50%, 0);
			width: 1.5em;
		}

		&:after {
			opacity: 0.5;
		}
	}
}
